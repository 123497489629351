<template>
  <CreateUpdateTemplate
    :routePreventDialog.sync="routePreventDialog"
    :customClass="'setting-create'"
    v-if="getPermission('setting:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <template v-if="pageLoading">
          <v-skeleton-loader
            type="text"
            class="custom-skeleton height-40px"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          {{ emailTemplate.template }}
          <v-icon small class="mx-4 color-custom-blue"
            >mdi-checkbox-blank-circle</v-icon
          >
          Email Template
        </template>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateEmailTemplateSetting"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="emailTemplateForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateEmailTemplateSetting"
      >
        <v-container fluid>
          <v-row v-if="pageLoading">
            <v-col md="8" offset-md="2" style="height: 500px; margin: auto">
              <v-progress-linear
                color="cyan accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col md="10">
              <v-container fluid v-bar class="create-email-template-vbar">
                <v-sheet>
                  <table class="width-100">
                    <tbody>
                      <tr>
                        <td class="py-2" width="300">
                          <label
                            class="color-custom-blue font-size-16 font-weight-600"
                            >Template Name</label
                          >
                        </td>
                        <td class="py-2">
                          <v-text-field
                            dense
                            filled
                            v-model.trim="emailTemplate.template"
                            :disabled="formLoading"
                            :loading="formLoading"
                            color="cyan"
                            label="Template Name"
                            readonly
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2" width="300">
                          <label
                            class="color-custom-blue font-size-16 font-weight-600"
                            >Subject</label
                          >
                        </td>
                        <td class="py-2">
                          <v-text-field
                            dense
                            filled
                            v-model.trim="emailTemplate.subject"
                            :disabled="formLoading"
                            :loading="formLoading"
                            color="cyan"
                            label="Subject"
                            :rules="[
                              validateRules.required(
                                emailTemplate.subject,
                                'Subject'
                              ),
                              validateRules.minLength(
                                emailTemplate.subject,
                                'Subject',
                                2
                              ),
                              validateRules.maxLength(
                                emailTemplate.subject,
                                'Subject',
                                100
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="py-2" width="300">
                          <label
                            class="color-custom-blue font-size-16 font-weight-600"
                            >Body</label
                          >
                        </td>
                        <td class="font-size-16 py-2">
                          <editor v-model="emailTemplate.admin_body" />
                        </td>
                      </tr>
                      <tr v-if="false">
                        <td class="py-2" width="300">
                          <label
                            class="color-custom-blue font-size-16 font-weight-600"
                          >
                            <template v-if="group != 'user'">Customer</template>
                            <template v-else>User</template>
                          </label>
                        </td>
                        <td class="font-size-16 py-2">
                          <editor v-model="emailTemplate.customer_body" />
                        </td>
                      </tr>
                      <template v-if="false && group != 'user'">
                        <tr>
                          <td class="py-2" width="300">
                            <label
                              class="color-custom-blue font-size-16 font-weight-600"
                              >In-App Notification</label
                            >
                          </td>
                          <td class="py-2">
                            <v-text-field
                              dense
                              filled
                              v-model.trim="emailTemplate.mobile_app"
                              :disabled="formLoading"
                              :loading="formLoading"
                              color="cyan"
                              label="In-App Notification"
                              :rules="[
                                validateRules.minLength(
                                  emailTemplate.mobile_app,
                                  'In-App Notification',
                                  2
                                ),
                                validateRules.maxLength(
                                  emailTemplate.mobile_app,
                                  'In-App Notification',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-sheet>
              </v-container>
            </v-col>
            <v-col
              md="2"
              v-if="availableVariables.length > 0"
              style="max-height: calc(100vh - 235px); overflow-y: scroll"
            >
              <table width="100%">
                <tr>
                  <td class="color-custom-blue font-size-18 font-weight-600">
                    Variables
                  </td>
                </tr>
                <tr
                  v-for="(variable, index) in availableVariables"
                  :key="index"
                >
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <td
                        v-on:click="copyText(index, variable)"
                        ref="text"
                        v-bind="attrs"
                        v-on="on"
                        class="font-size-16 font-weight-600 cursor-pointer py-1"
                      >
                        {{ variable }}
                      </td>
                    </template>
                    <span>Click here to Copy</span>
                  </v-tooltip>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SuccessEventBus, ClearEventBus } from "@/core/lib/message.lib";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "email-template-setting",
  data() {
    return {
      template: 0,
      pageLoading: true,
      group: null,
      availableVariables: new Array(),
      emailTemplate: new Object({
        customer_body: null,
        engineer_body: null,
        admin_body: null,
        mobile_app: null,
        template: null,
        subject: null,
      }),
    };
  },
  components: {
    editor: TinyMCE,
    CreateUpdateTemplate,
  },
  methods: {
    selectText(element) {
      let range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
    copyText(index, variable) {
      try {
        this.selectText(this.$refs.text[index]);
        document.execCommand("copy");
        ClearEventBus.$emit("clear:success");
        SuccessEventBus.$emit(
          "update:success",
          variable + " copied successfully."
        );
        window.getSelection().removeAllRanges();
      } catch (error) {
        this.logError(error);
      }
    },
    getOneEmailTemplateSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/email-template/" + _this.template })
        .then(({ data }) => {
          _this.availableVariables = data.variables || new Array();
          _this.group = data.group || null;
          _this.emailTemplate = new Object({
            customer_body: data.customer_body,
            engineer_body: null,
            admin_body: data.admin_body,
            mobile_app: data.mobile_app,
            template: data.template,
            subject: data.subject,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateEmailTemplateSetting() {
      const _this = this;
      if (!_this.$refs.emailTemplateForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "setting/email-template/" + _this.template,
          data: _this.emailTemplate,
        })
        .then(() => {
          _this.backForce = true;
          _this.$router.go(-1);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Setting", route: "profile.setting" },
      { title: "Email Template" },
      { title: "Update" },
    ]);

    _this.template = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.template > 0) {
      _this.getOneEmailTemplateSetting();
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.backForce) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
